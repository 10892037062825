import styled from 'styled-components';
import { Colors, Media } from '../lib/theme';
import { Link } from 'react-router-dom';
import { imprintRoute, privacyRoute, termsRoute } from '../lib/routes';

const FooterWrapper = styled.div`
    margin-top: 20px;
    border-bottom: 20px solid ${Colors.primary};
    background: #666;

    a {
        color: #fff;
        text-decoration: none;
    }

    @media ${Media.LG} {
        background: ${Colors.primary};
        border-bottom: none;
    }
`

const Footer = styled.footer`
    margin: 0 auto;
    padding: 15px 0;

    @media ${Media.LG} {
        width: 960px;
        padding: 25px 20px 20px;
    }
`

const LinkList = styled.ul`
    display: flex;
    flex-direction: column;
    text-align: center;
    margin: 0;
    padding: 0;
    font-size: 13px;
    line-height: 180%;
    list-style: none;

    @media ${Media.LG} {
        flex-direction: row;
        text-align: left;
    }

    li {
        margin: 0 0 5px;
        
        @media ${Media.LG} {
            margin: 0 40px 0 0;
        }
    }
`

export const AppFooter = () => {

    return (
        <FooterWrapper>
            <Footer>
                <LinkList>
                    <li>
                        <Link to={termsRoute()}>
                            Teilnahmebedingungen
                        </Link>
                    </li>
                    <li>
                        <Link to={privacyRoute()}>
                            Datenschutz
                        </Link>
                    </li>
                    <li>
                        <Link to={imprintRoute()}>
                            Impressum
                        </Link>
                    </li>
                </LinkList>
            </Footer>
        </FooterWrapper>
    )
}