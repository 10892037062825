/* eslint-disable react/jsx-no-target-blank */
import styled from 'styled-components';
import { CardIcon } from '../components/CardIcon';
import { BaseContainer, Col, GridContainer, PageWrapper } from '../components/Containers';
import { Hero } from '../components/Hero';
import { H2, H3, Important, List, SectionTitle } from '../components/Typo';
import { ReactComponent as CartIcon } from '../media/icons/cart.svg';
import { ReactComponent as MobileIcon } from '../media/icons/mobile.svg';
import { ReactComponent as ShamrockIcon } from '../media/icons/shamrock.svg';
import { ReactComponent as McIcon } from '../media/icons/mastercard.svg';
import zalandoLogo from '../media/images/zalando.png';
import { ReactComponent as McIdCheckIcon } from '../media/icons/mastercard-id-check.svg';
import IdCheckTeaser from '../media/images/teaser.jpg';
import { RegistrationForm } from '../components/RegistrationForm';
import { Button } from '../components/Button';
import { Media } from '../lib/theme';
import { useEffect } from 'react';
import { trackInternal } from '../lib/trackInternal';

const HintContainer = styled(BaseContainer)`
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
`

const StepsContainer = styled(GridContainer)`
    ${Col} {
        margin: 0 0 10px;

        @media ${Media.MD} {
            margin: 0;
        }
    }
`;

const InstructionCol = styled(Col)`
    display: flex;
    flex-direction: column;
`

const StyledMastercardIcon = styled(McIcon)`
    display: none;
    width: 90px;
    flex-shrink: 0;
    margin: 0 15px 0 70px;

    @media ${Media.MD} {
        display: block;
    }
`

const StyledMastercardIdCheckIcon = styled(McIdCheckIcon)`
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 2;
    height: 30px;
`

const IdCheckContainer = styled(BaseContainer)`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media ${Media.LG} {
        padding-right: 75px;
    }
`;

const StyledImg = styled.img`
    display: block;
    width: 100%;
`

export default function Index() {
    useEffect(() => {
        trackInternal('visit');

        // Campaign tracking
        const currentLocation = new URL(window.location.href);
        const campaign = currentLocation.searchParams.get('c');
        if (campaign) {
            trackInternal(`from-${campaign}`);
        }
    }, []);

    return (
        <>
            <Hero />

            <PageWrapper>
                <GridContainer>
                    <InstructionCol span={7}>
                        <BaseContainer className="flex-1">
                            <H2>

                                Kaufen Sie sicher im Internet ein
                            </H2>

                            <p>
                                Ihre Sparkassen-Card<sup>1</sup> eröffnet Ihnen neue Möglichkeiten: <br />
                                Sie können jetzt auch im Internet einkaufen – weltweit überall,
                                wo Mastercard<sup>®</sup> akzeptiert wird. Die Zahlung wird durch den
                                S-ID-Check abgesichert und der Betrag direkt von Ihrem
                                Girokonto abgebucht.
                            </p>

                            <H3>
                                Nutzen Sie Ihre Chance auf einen 50 € Zalando-Gutschein!
                            </H3>

                            <p>
                                <a href="https://www.sparkasse-siegen.de/fi/home/services-und-kontakt/online-services/alle-services/s-id-check-mastercard.html" target="_blank">Registrieren</a> Sie
                                Ihre Sparkassen-Card jetzt für S-ID-Check,
                                zahlen Sie vom 15.09. bis 15.11.2022 zweimal mit Ihrer Karte
                                online und gewinnen Sie mit etwas Glück einen von 1.000
                                Zalando-Gutscheinen à 50 €!
                            </p>

                            <img src={zalandoLogo} height="30" className="mt-15" alt="Zalando Logo" />
                        </BaseContainer>

                        <SectionTitle className="mt-20">
                            So machen Sie mit:
                        </SectionTitle>

                        <StepsContainer>
                            <Col span={4}>
                                <CardIcon
                                    counter={1}
                                    icon={<MobileIcon height="54px" />}
                                    text={(
                                        <>
                                            Laden Sie die S-ID-Check-App herunter und <a href="https://www.sparkasse-siegen.de/fi/home/services-und-kontakt/online-services/alle-services/s-id-check-mastercard.html" target="_blank">registrieren</a> Sie Ihre Sparkassen-Card. 
                                        </>
                                    )}
                                />
                            </Col>
                            <Col span={4}>
                                <CardIcon
                                    inverted
                                    counter={2}
                                    icon={<CartIcon height="50px" />}
                                    text="Zahlen Sie im Aktionszeitraum mindestens zweimal mit Ihrer Sparkassen-Card online."
                                />
                            </Col>
                            <Col span={4}>
                                <CardIcon
                                    counter={3}
                                    icon={<ShamrockIcon height="44px" />}
                                    text={(
                                        <>
                                            Nehmen Sie über das Teilnahmeformular <strong>„Chance sichern“</strong> an unserer Aktion teil. Viel Glück!
                                        </>
                                    )}
                                />
                            </Col>
                        </StepsContainer>
                    </InstructionCol>

                    <Col span={5}>
                        <BaseContainer>
                            <H2>
                                Chance sichern
                            </H2>

                            <RegistrationForm />
                        </BaseContainer>
                    </Col>
                </GridContainer>

                <HintContainer>
                    <div>
                        <Important primary>Wichtig:</Important> In vielen Online-Shops finden Sie nur den Begriff „Kreditkarte“ für die Kartenzahlung.
                        Wählen Sie diese Zahlungsart, denn Sie können problemlos mit Ihrer Sparkassen-Card weltweit
                        überall dort zahlen, wo Mastercard<sup>®</sup> akzeptiert wird!
                    </div>

                    <StyledMastercardIcon />
                </HintContainer>

                <SectionTitle>
                    Bereit fürs Bezahlen im Internet: Ihre Sparkassen-Card
                </SectionTitle>

                <GridContainer>
                    <Col span={5} className="relative">
                        <StyledImg src={IdCheckTeaser} />
                        <StyledMastercardIdCheckIcon />
                    </Col>

                    <Col span={7}>
                        <IdCheckContainer>
                            <div>
                                <Important className="mb-10">
                                    Sicher online bezahlen dank Mastercard<sup>®</sup> Identity Check<sup>™</sup>
                                </Important>

                                <List>
                                    <li>Höchste Sicherheit beim Bezahlen im Internet</li>
                                    <li>Nur ein Klick zum Bestätigen Ihrer Identität mit der App „S-ID-Check“</li>
                                    <li>Uneingeschränkt bezahlen – sicher und komfortabel</li>
                                    <li>Weltweiter Einsatz – auch ohne Datenverbindung mit der App</li>
                                </List>
                            </div>

                            <Button as="a" href="https://www.sparkasse-siegen.de/fi/home/services-und-kontakt/online-services/alle-services/s-id-check-mastercard.html" target="_blank" className="mt-20">
                                Laden Sie jetzt die S-ID-Check-App herunter und
                                registrieren Sie Ihre Karte für sicheres Online-Bezahlen
                            </Button>
                        </IdCheckContainer>
                    </Col>
                </GridContainer>
            </PageWrapper>
        </>
    );
}

