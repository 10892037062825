import styled from 'styled-components';
import { Colors, Media } from '../lib/theme';
import HeroImage from '../media/images/hero.jpg';
import { ReactComponent as MastercardIcon } from '../media/icons/mastercard.svg';

const Wrapper = styled.div`
    position: relative;
    display: flex;
    margin: 0 0 20px;
`

const Image = styled.img`
    width: 100%;
`

const Inner = styled.div`
    display: flex;
    width: 100%;
    padding: 0 20px;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;

    @media ${Media.LG} {
        width: 960px;
        padding: 0;
    }
`

const HeroMobile = styled.div`
    padding: 20px 10px 10px 10px;
    font-weight: bold;
    font-size: 15px;
    color: #333333;

    .title {
        color: ${Colors.primary};
        font-size: 22px;
    }

    @media ${Media.MD} {
        display: none;
    }
`

const Title = styled.div`
    display: none;
    float: left;
    margin: 0 0 100px;
    padding: 10px 20px;
    background: ${Colors.primary};
    color: #fff;
    font-size: 34px;
    line-height: 120%;
    font-weight: bold;

    @media ${Media.MD} {
        display: block;
    }
`

const Flag = styled.div`
    display: none;
    float: left;
    clear: both;
    padding: 10px 15px;
    line-height: 120%;
    font-size: 18px;
    background: ${Colors.primary};
    color: #fff;
    font-weight: bold;
    border: 2px solid #fff;
    transform: rotate(-7deg);

    @media ${Media.MD} {
        display: block;
    }
`

const StyledMastercardIcon = styled(MastercardIcon)`
    position: absolute;
    top: 20px;
    right: 20px;
    width: 60px;

    @media ${Media.MD} {
        width: 100px;
    }
`

export const Hero = () => {

    return (
        <>
            <HeroMobile>
                <div className="title">
                    Ihre Sparkassen-Card ist Ihr Schlüssel zum Online-Shopping
                </div>
                <div>
                    Jetzt sichern: 50 € Zalando-Gutschein
                </div>
            </HeroMobile>
            <Wrapper>
                <Image src={HeroImage} />
                <Inner>
                    <Title>
                        Ihre Sparkassen-Card<br />
                        ist Ihr Schlüssel zum<br />
                        Online-Shopping
                    </Title>

                    <Flag>
                        Jetzt sichern:<br />
                        50 € Zalando-Gutschein
                    </Flag>

                    <StyledMastercardIcon />
                </Inner>
            </Wrapper>
        </>
    )
}