import { useEffect } from 'react';
import { useNavigate } from 'react-router';


export default function Page404() {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/');
    }, [navigate]);

    return <div />;

}