import React from 'react';
import ReactDOM from 'react-dom/client';
import './normalize.css';
import './app.css';
import { BrowserRouter } from 'react-router-dom';
import { AppLayout } from './components/AppLayout';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
       <BrowserRouter>
            <AppLayout />
        </BrowserRouter>
    </React.StrictMode>
);
