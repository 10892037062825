import styled from 'styled-components';
import {Media} from '../lib/theme';

export const PageWrapper = styled.div`
    flex: 1;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0 auto;
    
    @media ${Media.LG} {
        width: 980px;
    }
`

export const GridContainer = styled.div`
    @media ${Media.MD} {
        display: grid;
        gap: 20px;
        grid-template-columns: repeat(12, minmax(0, 1fr));
    }
`

export const Col = styled.div<{span: number}>`
    grid-column: ${props => `span ${props.span}/span ${props.span}`};
`

export const BaseContainer = styled.div`
    background: #fff;
    padding: 10px;

    @media ${Media.LG} {
        padding: 35px 40px 25px;
    }
`