import styled from 'styled-components';
import { Colors } from '../lib/theme';

type CardProps = {
    counter: number,
    text: string | JSX.Element,
    icon: JSX.Element,
    inverted?: boolean
}

const Card = styled.div<{inverted?: boolean}>`
    height: 100%;
    padding: 9px 13px 10px;
    background: ${props => props.inverted ? '#fff' : Colors.primary};
    color: ${props => props.inverted ? '#000' : '#fff'};
`

const Counter = styled.span<{inverted?: boolean}>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 26px;
    height: 26px;
    font-size: 21px;
    background: ${props => props.inverted ? Colors.primary : '#fff'};
    color: ${props => props.inverted ? '#fff' : Colors.primary};
    border-radius: 100%;
    font-weight: bold;
`

const Text = styled.div`
    line-height: 120%;

    a {
        color: inherit;
    }
`

const Row = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 0 10px;

    svg {
        fill: 
    }
`

const IconWrapper = styled.span<{inverted?: boolean}>`
    flex: 1;

    svg {
        display: block;
        margin: 0 0 0 auto;
        fill: ${props => props.inverted ? Colors.primary : '#fff'};
    }
`

export const CardIcon = ({
    counter,
    text,
    inverted = false,
    icon
}: CardProps) => {

    return (
        <Card inverted={inverted}>
            <Row>
                <Counter inverted={inverted}>
                    {counter}
                </Counter>

                <IconWrapper inverted={inverted}>
                    {icon}
                </IconWrapper>
            </Row>

            <Text>
                {text}
            </Text>
        </Card>
    )
}