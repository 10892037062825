import { publicUrl } from './publicUrl';

// See webapp/htdocs/config/local.php for possible values -> trackings.public
export async function trackInternal(type: string) {
    const body = new FormData();
    body.append('type', type);

    await fetch(`${publicUrl}/api/v1/tracking/add`, {
        method: 'POST',
        body,
    });
}
