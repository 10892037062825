/* eslint-disable react/jsx-no-target-blank */
import styled from 'styled-components';
import { Colors, Fonts } from '../lib/theme';
import { Button } from './Button';
import imgGirokarte from '../media/images/karte.png';
import { FormEvent, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formSuccessRoute, HOST_URL, privacyRoute, termsRoute } from '../lib/routes';
import { trackInternal } from '../lib/trackInternal';

const Input = styled.input<{error?: boolean}>`
    display: block;
    width: 100%;
    margin: 0 0 7px;
    padding: 5px 10px;
    color: #666;
    font-size: 16px;
    font-family: ${Fonts.normal};
    border: 1px solid ${props => props.error ? Colors.primary : '#999999'};
    border-radius: 4px;
    background: #fff;
    -webkit-appearance: none;

    &::placeholder {
        color: #999999;
        font-size: 12px;
    }
`

const Checkbox = styled.div<{error?: boolean}>`
    display: flex;
    align-items: flex-start;
    margin-bottom: 7px;
    color: ${props => props.error ? Colors.primary : 'inherit'};

    input {
        appearance: none;
        width: 15px;
        height: 15px;
        margin: 2px 5px 0 0;
        flex-shrink: 0;
        background: #fff;
        border: 1px solid ${props => props.error ? Colors.primary : '#999999'};
        border-radius: 4px;

        &:checked {
            background-color: ${Colors.primary};
            background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
        }
    }
`

const CaptchaLabel = styled.div`
    display: flex;
    margin: 0 0 5px;
    align-items: center;

    span {
        padding: 2px 0 0;
    }

    img {
        width: 80px;
    }
`

const FormAction = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0 0;
`

const Required = styled.div`
    margin: 15px 0 10px;
    font-size: 11px;
    color: #999999;
`

const Hint = styled.div`
    font-size: 11px;
    line-height: 1.2;
`

const ErrorMessage = styled.p`
    line-height: 1.2;
    font-size: 14px;
    color: ${Colors.primary};
    font-weight: bold;
`

function getInitialComponentErrors() {
    return [
        { name: 'firstName', hasError: false },
        { name: 'lastName', hasError: false },
        { name: 'email', hasError: false },
        { name: 'birthday', hasError: false },
        { name: 'sid', hasError: false },
        { name: 'onlinePayment', hasError: false },
        { name: 'terms', hasError: false },
        { name: 'captcha', hasError: false },
    ];
}

export const RegistrationForm = () => {
    const navigate = useNavigate();
    const [captchaId, setCaptchaId] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [isSidCheck, setIsSidCheck] = useState(false);
    const [isOnlinePaymentCheck, setIsOnlinePaymentCheck] = useState(false);
    const [isTermsCheck, setIsTermsCheck] = useState(false);
    const [captchaResponse, setCaptchaResponse] = useState<string>('');
    const [componentErrors, setComponentErrors] = useState(() => getInitialComponentErrors());
    const [isFaulty, setIsFaulty] = useState(false);
    const [isTouched, setIsTouched] = useState(false);
    const [errorType, setErrorType] = useState<'VALIDATION' | 'ALREADY' | 'UNKNOWN'>('VALIDATION');
    const formRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
        resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function resetForm() {
        setFirstName('');
        setLastName('');
        setEmail('');
        setIsSidCheck(false);
        setIsOnlinePaymentCheck(false);
        setIsTermsCheck(false);
        setIsTouched(false);
        resetCaptcha();
    }

    useEffect(() => {
        if (isTouched) {
            trackInternal('fill-form');
        }
    }, [isTouched]);

    async function resetCaptcha() {
        const rawResponse = await fetch(`${HOST_URL}/api/v1/captcha`);
        if (!rawResponse.ok) {
            return;
        }

        const res: {id: string} = await rawResponse.json();
        setCaptchaId(res.id);
        setCaptchaResponse('');
    }

    function scrollToForm() {
        if (formRef.current) {
            const formOffset = formRef.current.offsetTop;

            if (window.pageYOffset > formOffset) {
                window.scroll({
                    top: formRef.current.offsetTop - 50,
                    behavior: 'smooth'
                })
            }
        }
    }

    function setValueResetError(numericId: number, value: boolean | string, name: string) {
        setComponentErrors(componentErrors.fill({ name: name, hasError: false }, numericId, numericId + 1));
        setIsTouched(true);

        switch (numericId) {
            case 0:
                if (typeof value === 'string') {
                    setFirstName(value);
                }
                break;
            case 1:
                if (typeof value === 'string') {
                    setLastName(value);
                }
                break;
            case 2:
                if (typeof value === 'string') {
                    setEmail(value);
                }
                break;
            case 3:
                if (typeof value === 'boolean') {
                    setIsSidCheck(value);
                }
                break;
            case 4:
                if (typeof value === 'boolean') {
                    setIsOnlinePaymentCheck(value);
                }
                break;
            case 5:
                if (typeof value === 'boolean') {
                    setIsTermsCheck(value);
                }
                break;
            default:
                setErrorType('VALIDATION');
                setIsFaulty(true);
                break;
        }
    }

    async function handleSubmit(e: FormEvent) {
        e.preventDefault();

        const validMail = /^\S+@\S+$/;
        const validations = [
            { name: 'firstName', hasError: firstName === '' },
            { name: 'lastName', hasError: lastName === '' },
            { name: 'email', hasError: !email || !validMail.test(email.toString()) },
            { name: 'sid', hasError: !isSidCheck },
            { name: 'onlinePayment', hasError: !isOnlinePaymentCheck },
            { name: 'terms', hasError: !isTermsCheck },
            { name: 'captchaResponse', hasError: captchaResponse === '' },
        ];

        const isValid = validations.every((er) => !er.hasError);
        if (!isValid) {
            setComponentErrors([...validations]);
            setErrorType('VALIDATION');
            setIsFaulty(true);
            scrollToForm();
            return;
        }

        const participant = {
            firstName,
            lastName,
            email,
        };
        const body = new FormData();
        body.append('participant', JSON.stringify(participant));
        body.append('captchaId', captchaId);
        body.append('captchaResponse', captchaResponse);

        const rawResponse = await fetch(`${HOST_URL}/api/v1/participant/add`, {body, method: 'POST'});
        setIsFaulty(false);

        if (rawResponse.ok) {
            navigate(formSuccessRoute());
            return;
        }

        const textResponse = await rawResponse.text();

        scrollToForm();
        resetCaptcha();

        if (textResponse.includes('already')) {
            setErrorType('ALREADY');
            setIsFaulty(true);
            return;
        }

        setErrorType('UNKNOWN');
        setIsFaulty(true);
    }

    return (
        <form onSubmit={handleSubmit} ref={formRef}>
            {isFaulty && (
                <ErrorMessage>
                    {errorType === 'VALIDATION' &&
                        'Das Formular ist unvollständig oder fehlerhaft ausgefüllt. Bitte prüfen Sie Ihre Angaben, bevor Sie fortfahren.'
                    }
                    {errorType === 'ALREADY' &&
                        <>
                            Wir haben bereits eine E-Mail an die von Ihnen angegebene Adresse gesendet. <br />
                            Sofern Sie Ihre Gewinnspielanmeldung noch nicht bestätigt haben, prüfen Sie bitte Ihr E-Mail-Postfach und klicken Sie auf den Aktivierungslink.
                        </>
                    }
                    {errorType === 'UNKNOWN' &&
                        'Ihre Daten konnten leider nicht abgeschickt werden. Bitte prüfen Sie Ihre Eingaben und probieren Sie es erneut.'
                    }
                </ErrorMessage>
            )}

            <Input
                type="text"
                name="firstName"
                value={firstName}
                onChange={e => setValueResetError(0, e.target.value, 'firstName')}
                placeholder="Ihr Vorname*"
                error={componentErrors[0].hasError}
            />

            <Input
                type="text"
                name="lastName"
                value={lastName}
                onChange={e => setValueResetError(1, e.target.value, 'lastName')}
                placeholder="Ihr Nachname*"
                error={componentErrors[1].hasError}
            />

            <Input
                type="email"
                name="email"
                value={email}
                onChange={e => setValueResetError(2, e.target.value, 'email')}
                placeholder="Ihre E-Mail-Adresse*"
                error={componentErrors[2].hasError}
            />

            <CaptchaLabel>
                <span>
                    Bitte berechnen Sie die Aufgabe:
                </span>

                {captchaId &&
                    <img
                        src={`${HOST_URL}/api/v1/captcha/${captchaId}.jpg`}
                        alt=''
                    />
                }

            </CaptchaLabel>

            <Input
                type="text"
                placeholder="Ergebnis eingeben*"
                value={captchaResponse}
                onChange={e => setCaptchaResponse(e.target.value)}
                error={componentErrors[6].hasError}
            />

            <Checkbox className="mt-20" error={componentErrors[3].hasError}>
                <input
                    id="sid"
                    name="sid"
                    checked={isSidCheck}
                    onChange={e => setValueResetError(3, e.target.checked, 'sid')}
                    type="checkbox"
                />

                <label htmlFor="sid">
                    Ja, meine Sparkassen-Card ist in der S-ID-Check-App registriert.
                </label>
            </Checkbox>

            <Checkbox error={componentErrors[4].hasError}>
                <input
                    id="onlinePayment"
                    name="onlinePayment"
                    checked={isOnlinePaymentCheck}
                    onChange={e => setValueResetError(4, e.target.checked, 'onlinePayment')}
                    type="checkbox"
                />

                <label htmlFor="onlinePayment">
                    Ja, ich habe mit meiner Sparkassen-Card im Aktionszeitraum mindestens zweimal online bezahlt.
                </label>
            </Checkbox>

            <Checkbox error={componentErrors[5].hasError}>
                <input
                    id="terms"
                    name="terms"
                    checked={isTermsCheck}
                    onChange={e => setValueResetError(5, e.target.checked, 'terms')}
                    type="checkbox"
                />

                <label htmlFor="terms">
                    Ja, ich habe die <a href={termsRoute()} target="_blank">Teilnahmebedingungen</a> und <a href={privacyRoute()} target="_blank">Datenschutzerklärung</a> gelesen und akzeptiere sie.
                </label>
            </Checkbox>

            <FormAction>
                <img src={imgGirokarte} height="59" alt="Sparkasse Girokarte" />

                <Button type="submit">
                    Jetzt teilnehmen
                </Button>
            </FormAction>

            <Required>
                * Pflichtfelder
            </Required>

            <Hint>
                Der Veranstalter behält sich vor, die Richtigkeit der Angaben zu überprüfen. Ihre Daten werden ausschließlich zur Durchführung der Aktion verwendet und nach Aktionsende gelöscht.
                <br /><br />
                <sup>1</sup> Sofern im Text von Sparkassen-Card die Rede ist, handelt es sich um eine Debitkarte.
            </Hint>
        </form>
    )
}
