import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BaseContainer, PageWrapper } from '../components/Containers';
import { Hero } from '../components/Hero';
import { H2 } from '../components/Typo';
import { HOST_URL } from '../lib/routes';

export default function Doi() {
    const {email, key} = useParams<{email: string, key: string}>();
    const [state, setState] = useState<boolean | null>(null);

    useEffect(() => {
        if (email === undefined || key === undefined) {
            setState(false);
            return;
        }

        (async () => {
            const body = new FormData();
            body.append('email', email);
            body.append('key', key);

            const rawResponse = await fetch(`${HOST_URL}/api/v1/participant/confirm`, {body, method: 'POST'});
            setState(rawResponse.ok);
        })();
    }, [email, key]);


    return (
        <> 
            <Hero />

            <PageWrapper>
                <BaseContainer>
                    <H2>
                        {state === true ?
                            'Anmeldung abgeschlossen!'
                        :
                            'Ihre Teilnahme'
                        }
                    </H2>

                    {state === null && (
                        <p>
                            Bitte warten Sie einen Moment, während wir Ihre Gewinnspielteilnahme abschließen.
                        </p>
                    )}

                    {state === true && (
                        <p>
                            Wunderbar, Sie haben Ihre Teilnahme bestätigt. Ihre Anmeldung zur Aktion ist erfolgreich gespeichert.<br />
                            Mit etwas Glück erhalten Sie nach Aktionsende per E-Mail von aktion@mastercard-sparkasse-siegen.de <br />
                            einen Zalando-Gutschein über 50 €.
                            <br /><br />
                            Wir drücken Ihnen die Daumen und wünschen Ihnen viel Freude beim sicheren Online-Shopping mit Ihrer Sparkassen-Card!
                        </p>
                    )}

                    {state === false && (
                        <p>
                            Leider konnten wir Ihre Gewinnspielteilnahme nicht abschließen. Bitte prüfen Sie die URL oder versuchen Sie es erneut.

                        </p>
                    )}
                </BaseContainer>
            </PageWrapper>
        </>
    )
}