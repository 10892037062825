import { BaseContainer, PageWrapper } from '../components/Containers';
import { H2 } from '../components/Typo';

export default function Finish() {

    return (
        <> 
            <PageWrapper>
                <BaseContainer>
                    <H2>Vielen Dank!</H2>

                    <p>
                        Sie erhalten in Kürze eine E-Mail von aktion@mastercard-sparkasse-siegen.de mit einem Link, um Ihre Teilnahme zu bestätigen. Bitte überprüfen Sie gegebenenfalls auch Ihren Spam-Ordner.
                    </p>
                </BaseContainer>
            </PageWrapper>
        </>
    )
}