import styled from 'styled-components';
import { Fonts, Colors } from '../lib/theme';
import { ReactComponent as Chevron } from '../media/icons/chevron-right.svg';

const Icon = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #fff;
    width: 21px;
    height: 21px;
    margin: 0 0 0 7px;
    border: 1px solid #fff;
    border-radius: 50%;

    svg {
        height: 10px;
        fill: #fff;
    }
`

const BaseButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    padding: 10px 20px 9px;
    font-size: 15px;
    line-height: 18px;
    font-family: ${Fonts.normal};
    background: ${Colors.primary};
    color: #fff;
    border: none;
    border-radius: 5px;
    text-decoration: none;

    &:hover {
        cursor: pointer;

        ${Icon} {
            background: #fff;

            svg {
                fill: ${Colors.primary};
            }
        }
    }
`

export const Button = ({children, ...props}: Parameters<typeof BaseButton>[0]) => {
    return (
        <BaseButton {...props}>
            {children}

            <Icon>
                <Chevron />
            </Icon>
        </BaseButton>
    );
};